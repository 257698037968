.homepage-layout {
  background: #f6f0ee !important;
  padding: 0;
  height: auto;
  line-height: 1.6;
  display: flex;

  .homepage-content {
    margin-top: 70px;

    .primary-button {
      background-color: $btn-primary-color;
      color: $white;
      border: 1px solid transparent;
      span {
        min-width: 150px !important;
      }
      height: 60px;
      font-size: 1.25rem;
      font-weight: bold;
      border-radius: 12px;
      border: none !important;
      padding: 5px 25px;

      &:hover {
        border: 1px solid $primary !important;
        background-color: $primary-lighter;
        color: $primary;
      }
    }

    // sections

    .social-network-for-creators {
      @media (min-width: 992px) {
        height: calc(100vh - 70px);
        // min-height: 802px;
      }
      display: flex;
      flex-direction: column;
      @media (max-width: 991px) {
        padding: 2rem 0 0;
      }
      .rotating-title {
        min-height: 189px;
        display: flex;
        align-items: center;
        margin-bottom: 0;
        @media (min-width: 992px) {
          min-height: 317px;
        }
      }
      > .container {
        width: 100%;
        @media (max-width: 1228px) {
          padding: 0 2rem;
        }
      }
      .buttons {
        margin-top: 1rem;

        .hashlink {
          background-color: #d91b3c;
          color: #ffffff;
          height: 58px;
          font-size: 20px;
          display: inline-block;

          font-weight: bold;
          border-radius: 12px;
          padding: 0.8rem 1.5rem;
          text-decoration: none;
          text-align: center;
          padding-top: auto;

          &:hover {
            background-color: $primary-lighter;
            color: $primary;
            border: 1px solid $primary;
          }
        }
        .ant-btn {
          margin-right: 1rem;
        }

        @media (max-width: 760px) {
          .ant-btn {
            margin-right: 1rem;
            width: 280px;
            margin-bottom: 1rem;
          }

          .hashlink {
            margin-right: 1rem;
            width: 280px;
            font-size: 16px;
          }
        }

        @media (max-width: 992px) {
          display: flex;
          flex-direction: column;
          align-items: center;
          button {
            margin-bottom: 1rem;
            &:last-child {
              margin-bottom: 0;
            }
          }
          .hashlink {
            margin-bottom: 1rem;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
      .container {
        flex: 1;
        display: flex;
        align-items: center;
        // padding: 0 2rem;
      }
      .arrow-down {
        border: none;
        outline: none;
        background: transparent;
        display: inline-block;
        height: auto;
        margin-top: 3rem;
        padding: 0;
        @media (max-width: 992px) {
          display: block;
          width: fit-content;
          margin: 3rem auto;
        }
      }
      .community-stats {
        display: none;
        margin-top: auto;
        background-color: #efe8e6;
        padding: 2rem 2rem 1rem;
        width: 100%;
        .ant-row {
          width: 100%;
        }
        .news-outlets {
          display: flex;
          flex-wrap: wrap;
          .icon {
            margin-right: 2rem;
            cursor: pointer;
            margin-bottom: 1rem;
          }
          @media (max-width: 992px) {
            margin-bottom: 2rem;
            margin-right: 1rem;
          }
        }
        .community-counters {
          display: flex;
          flex-wrap: wrap;
          & > div {
            background-color: #fff;
            cursor: pointer;
            border-radius: 8px;
            padding: 0.5rem 0.75rem;
            margin-right: 1rem;
            margin-bottom: 1rem;
            font-size: 1rem;
            &:last-child {
              margin-right: 0;
            }
            .icon {
              margin-right: 0.5rem;
            }
          }
        }
      }
      .litepaper-announcement {
        border-radius: 32px;
        padding: 1.2rem 1.2rem;
        border: 1px solid #fce5df;
        box-shadow: 2px 2px 3px #ff848411;
        position: fixed;
        height: auto;
        margin: 10px 10px;
        z-index: 999;
        right: 10px;
        bottom: 10px;
        
        .litepaper-card {
          display: flex;
          flex-direction: row;
          font-size: 1rem;
          position: fixed;
          margin-right: 4px;
          margin-bottom: 4px;
        }

      }
    }

    .cherry-vision {
      padding: 10rem 2rem $section-y-padding;
      position: relative;
      min-height: 732px;
      display: flex;
      align-items: center;

      @media (max-width: 550px) {
        padding: 10rem 2rem;
      }

      @media (max-width: 786px) {
      }

      .main-container {
        position: relative;
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;

        .cherry-vision-image {
          margin-top: 5rem;
        }

        .vision-text {
          margin-bottom: 3rem;
        }

        .vision-points {
          margin-top: 1.5rem;
          display: flex;
          flex-direction: row;

          .img-cap {
            margin-right: 1.5rem;
          }

          .w-100 {
            width: 100%;
          }
        }
      }
    }

    .cherry-chain-card {
      padding: #{$section-y-padding + 7rem} 2rem $section-y-padding;

      //   margin-bottom: 8rem;
      //   margin: 12rem 0rem 5rem;
      .cherry-chain-image {
        position: absolute;
        z-index: 1;
        top: -9rem;
        width: 49%;
        right: calc(24px + 2rem);
        display: none;
        @media (min-width: 992px) {
          display: block;
        }
      }
      @media (max-width: 550px) {
        padding: 0rem 0rem;
      }

      @media (max-width: 786px) {
      }

      .cherry-card {
        width: 100%;
        border-radius: 32px;
        padding: 2rem 2rem;
        border: 1px solid #fce5df;
        box-shadow: 2px 2px 3px #ff848411;

        .ant-card-body {
          .ant-row:first-child {
            margin-bottom: 2rem;
            .ant-col {
              position: relative;
              z-index: 4;
            }
            @media (min-width: 992px) {
              margin-bottom: 5rem;
            }
          }
        }

        @media (max-width: 750px) {
          width: 100%;
          border-radius: 0px;
        }

        .text-18 {
          font-size: 22px !important;
          line-height: 1.6;
        }

        .text-20 {
          font-size: 20px;
        }

        .text-24 {
          font-size: 24px;
        }

        .cherry-chain-whitepaper-button {
          background-color: #26262652 !important;
          color: $white !important;
          border-radius: 12px;
          padding: 1rem 2rem;
          text-align: center;
          height: auto !important;
          font-weight: bold;
          margin: 1.2rem 0rem;
          white-space: normal;
          word-wrap: break-word;
          &:hover {
            border-color: transparent;
          }

          .icon-paper {
            margin-right: 15px !important;
          }

          @media (max-width: 750px) {
            height: auto;
            font-size: 16px;
          }

          @media (max-width: 475px) {
            width: 100%;
            font-size: 14px;
            padding: 1rem 3rem 1rem 1.5rem;
            height: auto;
            .icon-paper {
              display: none;
            }
          }

          @media (max-width: 375px) {
            width: 100%;
            font-size: 12px;
            padding: 1rem 3rem 1rem 1.5rem;
            height: auto;
            .icon-paper {
              display: none;
            }
          }

          @media (max-width: 325px) {
            width: 100%;
            font-size: 12px;
            padding: 1rem 1rem 1rem 1rem;
            height: auto;
            .icon-paper {
              display: none;
            }
          }
        }

        .start-developing-button {
          background-color: $btn-primary-color;
          color: $white !important;
          border-radius: 12px;
          padding: 1rem 2rem;
          height: auto;
          font-weight: bold;
          white-space: normal;
          word-wrap: break-word;
          margin: 1.2rem 0rem;
          

          @media (max-width: 750px) {
            width: 100%;
            font-size: 16px;
            padding: 1rem 3rem 1rem 1.5rem;
            height: auto;
          }

          @media (max-width: 450px) {
            width: 100%;
            font-size: 14px;
            padding: 1rem 3rem 1rem 1.5rem;
            height: auto;
          }

          @media (max-width: 375px) {
            width: 100%;
            font-size: 12px;
            padding: 1rem 3rem 1rem 1.5rem;
            height: auto;
          }

          &:hover {
            background-color: $white;
            border: 1px solid $primary !important;
            color: $primary !important;
          }

        }
      }
    }

    .place-where-creators-thrive {
      padding: $section-y-padding 2rem;
      //   margin: 5rem 0;
      position: relative;
      @media (max-width: 550px) {
        padding: 3em 2rem;
      }
      @media (max-width: 786px) {
      }
      .ant-col {
        &:nth-child(1) {
          @media (min-width: 992px) {
            text-align: center;
          }
        }
        &:nth-child(2) {
          margin-bottom: 2rem;
          @media (min-width: 992px) {
            .ant-typography {
              text-align: center;
            }
          }
        }
      }
      display: flex;
      align-items: center;
      .main-container {
        position: relative;
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;

        .text-center {
          text-align: center;
        }

        .creators-thrive-feature {
          margin-bottom: 3rem;
          h3.ant-typography {
            color: #e43c5c;
            margin-bottom: 0.5rem;
          }
        }
      }
    }

    .join-the-cherry-ecosystem {
      padding: 14rem 2rem 8rem 2rem;
      background: url($CDN + "/landing/token-sale/wave_bg1.svg");
      background-position: bottom;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      min-height: 932px;
      position: relative;
      @media (max-width: 550px) {
        padding: 3em 0rem;
      }

      @media (max-width: 786px) {
      }

      .main-container {
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        background: #e33b5c;
        box-sizing: border-box;
        padding: 60px;
        border-radius: 32px;
        position: relative;
        @media (max-width: 991px) {
          padding: 0 2rem 4rem;
        }

        .image-ecosystem {
          position: absolute;
          bottom: 90px;
          right: 2rem;
          height: 91%;
          z-index: 1;
          @media (max-width: 991px) {
            right: 0;
            bottom: 0;
            height: auto;
            position: relative;
            margin-top: -18%;
            margin-bottom: 2rem;
          }
        }

        .ant-row {
          z-index: 5;
          position: relative;
        }

        @media (max-width: 800px) {
          text-align: center;
        }

        @media (max-width: 550px) {
          border-radius: 0;
        }

        @media (max-width: 786px) {
          .ant-col:nth-child(1) {
            order: 2;
          }

          .ant-col:nth-child(2) {
            order: 1;
            margin-bottom: 2rem;
          }
        }

        h1,
        h2,
        h3 {
          color: #fff;
        }

        .separator {
          margin-top: 2rem;
          display: flex;
          justify-content: space-between;
          align-items: top;
          gap: 25px;

          @media (max-width: 800px) {
            flex-direction: column;
            text-align: center;
            .column {
              text-align: center;
            }
          }

          @media (max-width: 550px) {
            .column {
              text-align: left;
            }
          }

          .column {
            display: flex;
            flex-direction: column;
            @media (max-width: 991px) {
              margin-bottom: 2rem;
              &:last-child {
                margin-bottom: 0;
              }
            }

            @media (max-width: 550px) {
              input,
              .ant-btn {
                width: 100%;
              }
              input {
                text-align: left;
              }
            }

            .ant-typography:nth-child(2) {
              margin-top: -1rem;
              color: #f3b3c0;
            }

            .text-white {
              color: $white;
            }

            .ant-btn {
              background: #fff;
              color: #e33b5c;
              padding: 0 2rem;
              height: 50px;
              border: none !important;
              font-weight: default !important;
              border-radius: 12px;
              margin-top: auto;
              @media (min-width: 992px) {
                max-width: 300px;
              }
            }

            a.ant-btn {
              display: flex;
              align-items: center;
              justify-content: center;
              text-decoration: none;
            }

          

            input {
              background: #cf2e4e;
              color: #fff;
              padding: 0 2rem;
              height: 50px;
              border-radius: 12px;
              border: 0;
              outline: 0;

              &::placeholder {
                color: #fff;
              }

              &:focus {
                border: 0;
                outline: 0;
              }

              .input-email {
                margin-top: auto;
                
                .icon {
                  rect {
                    fill: #fff !important;
                  }
                  path {
                    stroke: #cf2e4e !important;
                  }
                }
              }
              
            }
          }
        }
      }
    }

    .nft-governance {
      padding: $section-y-padding 2rem;
      margin-top: 4rem;
      position: relative;
      display: flex;
      align-items: center;

      @media (max-width: 550px) {
        padding: 4rem 2rem;
      }

      @media (max-width: 786px) {
      }

      .main-container {
        position: relative;
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
      }

      .disabled-button {
        background-color: #26262652 !important;
        color: $white !important;
        border-radius: 12px;
        padding: 1rem 2rem;
        height: auto;
        font-weight: bold;
        margin: 1.2rem 0rem;

        &:hover {
          border: 0px;
        }
      }

      .ant-btn {
        margin: 1.2rem 0rem;
      }
    }

    .governance {
      padding: $section-y-padding 2rem;
      position: relative;
      display: flex;
      align-items: center;

      @media (max-width: 550px) {
        padding: 4rem 2rem;
      }

      @media (max-width: 786px) {
      }

      @media (min-width: 993px) {
        .image-wrap {
          img {
            display: block;
            margin-left: auto;
          }
        }
      }

      .main-container {
        position: relative;
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
      }

      .disabled-button {
        background-color: #26262652 !important;
        color: $white !important;
        border-radius: 12px;
        padding: 1rem 2rem;
        height: auto;
        font-weight: bold;
        margin: 1.2rem 0rem;

        &:hover {
          border-color: transparent;
        }
      }
    }

    .meet-the-worldwide-community {
      padding: $section-y-padding 2rem;
      .social-icons {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        height: 100%;
        padding: 4rem;
        max-width: 400px;
        margin: 0 auto;
        @media (min-width: 992px) {
          border-right: 2px solid #f6ddd5;
          padding: 5rem;
        }
        .icon-wrapper {
          width: calc(50% - 1.5rem);
          // height: 100px;
          margin-bottom: 3rem;
          background-color: #fff;
          border-radius: 18px;
          padding: 1.75rem;
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow: 0px 10px 50px rgba($color: #ff8484, $alpha: 0.09);
          border: 1px solid #fce5df;
          transition: 0.2s ease-in-out;
          &:nth-child(3),
          &:nth-child(4) {
            margin-bottom: 0;
          }
          svg path {
            fill: #e43c5c !important;
          }
          &:hover {
            transform: scale(1.1);
          }
        }
      }
      .ant-col:nth-child(2) {
        @media (min-width: 992px) {
          padding: 1rem 3rem !important;
        }
      }
      .input-email {
        position: relative;
        max-width: 320px;
        margin-top: 2rem;
        border-radius: 12px !important;
        border: none;

        input[type="email"] {
          padding: 1rem;
          border-radius: 12px !important;
          color: #fff;
          font-size: 18px !important;
          line-height: 1.6rem;
          background-color: #262626;
        }
        button {
          position: absolute;
          background-color: transparent;
          outline: none;
          padding: 0;
          border-radius: 12px !important;
          border: none;
          right: 8px;
          top: 50%;
          transform: translateY(-50%);
          .image-wrap {
            img {
              display: block;
              margin-left: auto;
            }
          }

          .disabled-button {
            background-color: #26262652 !important;
            color: $white !important;
            border-radius: 12px;
            padding: 1rem 2rem;
            height: auto;
          }
        }
      }

      .roadmap {
        padding: $section-y-padding 2rem;

        @media (max-width: 550px) {
          padding: 4rem 2rem;
        }

        @media (max-width: 786px) {
        }

        .roadmap-title {
          margin-bottom: 4rem;
        }
        .roadmap-item {
          display: flex;
          align-items: center;
          padding: 3rem;
          margin-bottom: 4rem;
          border-radius: 2rem;
          background-color: #eee8e6;
          position: relative;
          .text-wrap {
            // margin: 0 1rem;
            margin-top: 1rem;
          }
          &.roadmap-active {
            background-color: #e33b5c;
            color: #fff;
            .ant-btn {
              background-color: #fff;
              color: #e33b5c;
            }
            .ant-typography {
              color: #fff;
            }
          }
        }
      }

      .cherry-labs {
        padding: $section-y-padding 2rem;

        @media (max-width: 550px) {
          padding: 4rem 2rem;
        }

        @media (max-width: 786px) {
        }

        position: relative;

        display: flex;
        align-items: center;

        .main-container {
          position: relative;
          width: 100%;
          max-width: 1200px;
          margin: 0 auto;
        }
      }

      .cherry-blog {
        display: block;
        padding: 4rem 10rem;
        margin-top: 4rem;

        .main-container {
          position: relative;
          width: 100%;
          max-width: 1200px;
          margin: 0 auto;

          .ant-col {
            &:nth-child(1) {
              // background: red;
              display: flex;
              justify-content: space-between;
            }

            &:nth-child(2) {
              // background: pink;
              // display: flex;
              // justify-content: space-between;

              .ant-col {
              }

              .blog-card {
                margin-top: 2rem;
                .image {
                  width: 90%;
                  height: 490px;
                  background: #fff;
                  border-radius: 24px;
                  position: relative;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
                .text {
                  margin-top: 2rem;
                  .ant-typography {
                    color: #e43c5c;
                  }
                }
              }
            }
          }
        }
      }

      .meet-the-community {
      }

      .token-and-faq {
        padding: 5rem 2rem;
        .image-wrap {
          img {
            width: 100%;
          }
        }
        .token {
          margin: 0 auto;
        }
        .faq {
          margin-top: 8rem;
        }
      }

      .mb-5 {
        margin-bottom: 5px !important;
      }

      .mb-10 {
        margin-bottom: 10px !important;
      }

      .mb-15 {
        margin-bottom: 15px !important;
      }

      .mt-5 {
        margin-top: 5px !important;
      }

      .mt-10 {
        margin-top: 10px !important;
      }

      .mt-15 {
        margin-top: 15px !important;
      }
    }
  }
}

.meet-the-worldwide-community {
  padding: 5rem 2rem;
  .social-icons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    height: 100%;
    padding: 4rem;
    max-width: 400px;
    margin: 0 auto;
    @media (min-width: 992px) {
      border-right: 2px solid #f6ddd5;
    }
    .icon-wrapper {
      width: calc(50% - 1.5rem);
      margin-bottom: 3rem;
      background-color: #fff;
      border-radius: 18px;
      padding: 1.75rem;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 10px 50px rgba($color: #ff8484, $alpha: 0.09);
      border: 1px solid #fce5df;
      &:nth-child(3),
      &:nth-child(4) {
        margin-bottom: 0;
      }
      svg path {
        fill: #e43c5c !important;
      }
    }
  }
  .ant-col:nth-child(2) {
    @media (min-width: 992px) {
      padding: 1rem 3rem !important;
    }
  }
  .input-email {
    position: relative;
    max-width: 320px;
    margin-top: 2rem;
    input[type="email"] {
      padding: 1rem;
      border-radius: 8px;
      color: #fff;
      background-color: #000;
    }
    button {
      position: absolute;
      background-color: transparent;
      outline: none;
      padding: 0;
      border: none;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      height: auto;
    }
  }
}

.roadmap {
  padding: 5rem 2rem;

  @media (max-width: 550px) {
    padding: 4rem 2rem;
  }

  @media (max-width: 786px) {
  }

  .roadmap-title {
    margin-bottom: 4rem;
  }
  .roadmap-item {
    display: flex;
    align-items: center;
    padding: 3rem;
    margin-bottom: 4rem;
    border-radius: 2rem;
    background-color: #eee8e6;
    .text-wrap {
      // margin: 0 1rem;
      margin-top: 1rem;
    }
    &.roadmap-active {
      background-color: #e33b5c;
      color: #fff;
      .ant-btn {
        background-color: #fff;
        color: #e33b5c;
      }
      .ant-typography {
        color: #fff;
      }
    }
  }
}

.cherry-labs {
  padding: 5rem 2rem;
  //   margin-top: 4rem;

  @media (max-width: 550px) {
    padding: 4rem 2rem;
  }

  @media (max-width: 786px) {
  }

  position: relative;

  display: flex;
  align-items: center;

  .main-container {
    position: relative;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
  }
}

.cherry-blog {
  display: block;
  padding: 4rem 10rem;
  margin-top: 4rem;

  .main-container {
    position: relative;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;

    .ant-col {
      &:nth-child(1) {
        // background: red;
        display: flex;
        justify-content: space-between;
      }

      &:nth-child(2) {
        // background: pink;
        // display: flex;
        // justify-content: space-between;

        .ant-col {
        }

        .blog-card {
          margin-top: 2rem;
          .image {
            width: 90%;
            height: 490px;
            background: #fff;
            border-radius: 24px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .text {
            margin-top: 2rem;
            .ant-typography {
              color: #e43c5c;
            }
          }
        }
      }
    }
  }
}

.token-and-faq {
  padding: $section-y-padding 2rem;
  .image-wrap {
    img {
      width: 100%;
      max-width: 437px;
      display: block;
      margin: 0 auto;
    }
  }
  .token {
    margin: 0 auto;
  }
  .faq {
    margin-top: 13rem !important;
  }
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}
