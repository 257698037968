body {
  font-size: 16px;
  line-height: 1.6;
  color: #333;
}

.container-fluid {
  margin: 0 auto;

  // padding: 0 55px !important;

  // @media (min-width: 50px) {
  //   padding: 0 10px !important;
  // }
  // @media (min-width: 550px) {
  //   padding: 0 10px !important;
  // }

  // @media (min-width: 750px) {
  //   padding: 0 55px !important;
  // }
}

.ant-layout {
  background: #fff !important;
}

.main-layout .ant-layout-header {
  background: $primary-lighter !important;
  padding: 0;
  height: auto;
  line-height: 1.6;
  margin-top: 10px;
}

p {
  font-size: 16px;
  line-height: 1.6;
}

.button {
  background-color: #d91b3c;
  color: #fff;
  font-weight: 700;
}

.button:hover {
  background-color: white;
  color: #d91b3c;
  border: 1px solid #d91b3c;
}

.ant-btn-primary {
  background-color: #d91b3c;
  color: #fff;
  font-weight: 700;
  border: 0;
}

.button_outline {
  background-color: transparent;
  margin-left: 10px;
  font-weight: 700;
  border: 1px solid #000;

  &:hover {
    border: 1px solid $primary;
    color: $primary;
  }
}

.link {
  // margin-top: 5px;
  // margin-left: 15px;
  // padding: 0px 5px;
  text-decoration: none;
  color: #000000;
  // margin-right: 10px;

  &:hover {
    color: #d91b3c;
  }
}

.token-sale-link {
  margin-top: 5px;
  margin-left: 15px;
  padding: 0px 5px;
  text-decoration: none;
  color: #d91b3c;
  font-weight: 700;
  font-size: 22px;
  margin-right: 10px;

  &:hover {
    color: #df697d;
  }

  @media (max-width: 991px) {
    margin: 0 5px;
    padding: 0;
  }
}

.main {
  margin-top: 50px;
}

/* 
  title holder
  ------------
  */
.titleHolder {
  text-align: center;
  position: relative;
  padding: 0 0 20px;
  margin: 0 0 40px;
}

.titleHolder:after {
  transform: translateX(-50%);
  content: "";
  background: #d91b3c;
  position: absolute;
  bottom: 0;
  left: 50%;
  height: 3px;
  width: 50px;
}

.titleHolder h2 {
  font-size: 28px;
  margin: 0;
}

/* 
  header
  ------ 
  */
.ant-layout-header {
  position: fixed;
  left: 0;
  height: auto;
  right: 0;
  background-color: $primary-lighter;
  z-index: 999;
  padding: 0;
  padding: 0px 10px;

  .container-fluid {
    background-color: $primary-lighter;
  }
  // @media (max-width: 766)
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
}

.header .logo img {
  width: 150px;
  height: 100%;
}

.footer .logo img {
  width: 150px;
  height: 45px;
}

.header .ant-anchor-wrapper {
  background: none;
}

.header .ant-anchor {
  display: flex;
}

.header .ant-anchor-ink {
  display: none;
  color: #000;
}

.header .ant-anchor-link {
  padding: 0 20px;
  margin-top: 5px;
  color: #000;
  line-height: 1.4;
  position: relative;
}

.ant-anchor-link-title ant-anchor-link-title-active {
  color: #000;
}

// .header .ant-anchor-link:before,
// .header .ant-anchor-link:after {
// 	content: '';
// 	background: #D91B3C;
// 	width: 1px;
// 	position: absolute;
// 	top: 4px;
// 	bottom: 2px;
// 	left: 30%;
// 	opacity: 0;
// 	transition: all 0.3s linear;
// }

// .header .ant-anchor-link:after {
// 	left: auto;
// 	right: 30%;
// }

// .header .ant-anchor-link.ant-anchor-link-active:before,
// .header .ant-anchor-link:hover:before {
// 	left: 5%;
// 	opacity: 1;
// }

// .header .ant-anchor-link.ant-anchor-link-active:after,
// .header .ant-anchor-link:hover:after {
// 	right: 5%;
// 	opacity: 1;
// }

/* ant menu */
.header .ant-menu {
  border: 0;
  background: none !important;
}

.header .ant-menu li.ant-menu-item {
  position: relative;
  color: #333;
}

.header .ant-menu li.ant-menu-item:before,
.header .ant-menu li.ant-menu-item:after {
  content: "";
  background: #d91b3c;
  width: 1px;
  position: absolute;
  top: 2px;
  bottom: 2px;
  left: 30%;
  opacity: 0;
  transition: all 0.3s linear;
}

.header .ant-menu li.ant-menu-item:after {
  left: auto;
  right: 30%;
}

.header .ant-menu li.ant-menu-item,
.header .ant-menu li.ant-menu-item-selected {
  border: 0 !important;
}

.header .ant-menu li.ant-menu-item-selected,
.header .ant-menu li.ant-menu-item:hover {
  color: #d91b3c;
  background: none;
}

.header .ant-menu li.ant-menu-item-selected:before,
.header .ant-menu li.ant-menu-item:hover:before {
  left: 5%;
  opacity: 1;
}

.header .ant-menu li.ant-menu-item-selected:after,
.header .ant-menu li.ant-menu-item:hover:after {
  right: 5%;
  opacity: 1;
}

/* 
  block
  ----- 
  */
.block {
  padding: 60px 0;
  border-bottom: 1px solid #d9d9d9;
}

/* 
  footer
  ------
  */
.ant-layout-footer {
  background-color: $primary-lighter;
  color: rgb(58, 58, 58) !important;
  padding: 10px 0 !important;
  text-align: center;
}

// .footer {
// 	display: flex;

// }

.footer .logo {
  font-size: 22px;
  text-transform: uppercase;
  margin: 0 0 20px;
}

.footer .fas {
  color: #d91b3c;
  font-size: 28px;
  margin: 0 5px 0 0;
}

.footer .logo a {
  color: #fff;
}

.footer .socials {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  font-size: 24px;
}

.footer .socials li {
  margin: 0 20px;
}

.footer .socials a {
  color: #fff;
}

.footer .socials a:hover {
  color: #d91b3c;
}

.footer .goTop:hover {
  opacity: 0.7;
}

// New Footer from XD
#footer {
  // padding: 12rem 2rem 0rem 2rem;
  margin-top: 6rem;
  background: #f6f0ee;
  width: 100%;
  // min-height: 500px;
  height: auto;
  padding: 0 2rem;
  font-size: 16px !important;
  // @media (min-width: 992px) {
  //   padding: 0 85px;
  // }

  hr {
    border: 1px solid #f3eae6;
    margin: 2rem 0;
  }

  .text-16 {
    font-size: 16px;
    line-height: 1.6rem;
  }

  .text-15 {
    font-size: 15px;
    line-height: 1.8rem;
  }

  .container:nth-of-type(2) {
    text-align: right;
  }

  .ant-row {
    .ant-col {
      &:nth-child(1) {
        //  background: blue;
        text-align: left;

        img {
          width: 200px;
        }

        .ant-typography {
          margin: 1rem 0 2rem 0;
          font-size: 16px;

          span {
            color: $primary;
          }
        }

        .footer-social {
          width: 100%;
          height: 30px;
          display: flex;

          .social-item {
            margin: 0 8px;
            &:hover {
              cursor: pointer;
            }
          }
        }
      }

      &:nth-child(2) {
        //  background: pink;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        b {
          color: $primary;
        }

        .column {
          flex: 1 2 150px;
          margin-right: 1rem;
          &:last-child {
            margin-right: 0;
          }
          // background: green;

          .list {
            text-align: left;
            // &:nth-child(n +2) {
            margin-top: 1.5rem;
            display: flex;
            flex-direction: column;
            // }

            b,
            a {
              display: block;
              text-decoration: none;
            }

            b {
              font-size: 18px;
              margin-bottom: 0.2rem;
            }

            a {
              color: inherit;
              font-weight: 500;
              font-size: 16px;
              line-height: 1.6rem;

              &:hover {
                color: $primary;
              }
            }
          }
        }
      }
    }
  }
}

.mobileVisible {
  display: none;
}

.mobileHidden {
  display: block;
}

.text-white {
  color: $white;
}

.text-primary {
  color: $primary;
}

.bg-primary {
  background-color: $primary;
}

.text-40 {
  font-size: 36px;
}

.ant-anchor-link-title {
  margin-top: 20px;
}

.start-your-page-button {
  font-size: 25px;
  height: 60px;
}

.start-your-page-button:not(:hover) {
  background-color: $primary !important;
  color: $white !important;
}

// .ant-drawer-content-wrapper {
//   width: 35% !important;
// }
@media only screen and (max-width: 900px) {
  .ant-drawer-content-wrapper {
    width: 70% !important;
  }

  .text-40 {
    font-size: 25px !important;
  }

  .start-your-page-button {
    font-size: 22px;
    height: 50px;

    &:hover {
      background-color: $white !important;
      color: $primary !important;
      border: 1px solid $primary;
    }
  }

  .start-your-page-button:not(:hover) {
    background-color: $primary !important;
    color: $white !important;
  }
}

.bg-transparent {
  background-color: rgba(255, 255, 255, 0);
  border: 0px;
  font-size: 24px;
  height: 100%;

  &:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0);
  }
}

/* 
  responsive
  ----------
  */
@media only screen and (max-width: 991px) {
  .pricingBlock .ant-row > div:nth-child(2) .ant-list-item {
    transform: scale(1.14);
  }
}

@media only screen and (max-width: 767px) {
  .pricingBlock .ant-row > div:nth-child(2) .ant-list-item {
    transform: scale(1);
  }

  .mobileVisible {
    display: block;
  }

  .mobileHidden {
    display: none;
  }
}

.rounded-number {
  background-color: #ef648b;
  color: $white;
  padding: 12px;
  width: 50px;
  height: 50px;
  text-align: center;
  font-weight: bold;
  border-radius: 50%;
}

.faq {
  .faq-header {
    color: $pink;
  }
  .text-body {
    padding-bottom: 20px;
  }
  .faq-question {
    font-size: $text-header-5;
    line-height: $text-header-5-line-height;
  }
  p {
    font-size: $text-body2;
    line-height: $text-body2-line-height;
  }
}

// Contact Form styling
.contactForm {
  padding: 2rem 1rem;
  @media (min-width: 992px) {
    padding: 40px 20px;
  }

  .container {
    box-shadow: 2px 10px 60px rgba(75, 0, 129, 0.12);
    border-radius: 10px;
    padding: 2rem 1rem;
    @media (min-width: 992px) {
      padding: 40px;
    }
  }

  .ant-row {
    margin-top: 40px;

    .ant-col {
      //   background: green;

      .ant-typography {
        text-align: left;
      }

      label {
        display: none;
      }

      input,
      textarea {
        border: none;
        border-radius: 5px;
        background: #f7f7f7;
        padding: 1rem;
        box-sizing: border-box;
        font-weight: 600;

        &::placeholder {
          color: #999999;
        }
      }

      textarea {
        max-height: 500px;
        min-height: 150px;
      }

      .ant-btn-primary {
        width: 100%;
      }

      .contactInfo {
        width: 100%;
        height: auto;
        // background: #f2f2f2;

        .row {
          display: flex;
          align-items: flex-start;
          // justify-content: space-between;
          gap: 20px;
          min-height: 40px;
          // background: red;
          align-items: center;

          .ant-typography {
            font-weight: 600;
          }

          &:nth-child(2),
          &:nth-child(3) {
            margin-top: 20px;
          }

          .icon {
            width: 20px;

            img {
              margin-top: -8px;
            }
          }

          .text {
            max-width: 300px;
          }
        }
      }

      .social {
        width: 100%;
        // height: auto;
        // background: #f2f2f2;
        margin-top: 40px;

        .box {
          width: 30px;
          height: 30px;
          background: #f2f2f2;
          display: inline-block;

          &:nth-child(n + 2) {
            margin-left: 15px;
          }
        }
      }

      .map {
        width: 100%;
        height: 200px;
        background: #f2f2f2;
        margin-top: 40px;
        border-radius: 5px;
      }
    }
  }
}

.faq {
  margin: 5rem 0 0;
}

.faq-title {
  margin-bottom: 2rem;
}

.h-100 {
  height: 100%;
}

.faq-item {
  max-height: 1000px;
  text-align: left;
  a {
    color: $pink;
  }
  .accordion-toggle {
    display: flex;
    position: relative;
    color: $pink;
    p,
    .ant-typography {
      margin-bottom: 0;
      color: $pink;
    }
    .rounded-number {
      margin-right: 2rem;
      flex: none;
    }
    .toggle-icon {
      position: absolute;
      margin-left: auto;
      font-weight: 500;
      font-size: 3rem;
      right: 0;
      top: 50%;
      transform: translateY(-57.5%);
      @extend .noselect;
    }
    @media (max-width: 500px) {
      padding-top: 1rem;
      padding-bottom: 1rem;
      flex-direction: column;
      .toggle-icon {
        top: calc(25px + 0.5rem);
      }
      .rounded-number {
        margin-bottom: 1rem;
        margin-right: auto;
      }
    }
  }
  .accordion-collapse {
    padding: 0;
    @media (max-width: 500px) {
      margin-left: 0;
    }
  }
}

.social-channels {
  position: relative;
  padding: 2rem 1rem;
  margin: 10px 20px;
  @media (min-width: 992px) {
    padding: 45px;
  }

  .important-heading {
    font-size: 55px;
    text-align: left;

    @media (min-width: 500px) {
      font-size: 35px;
    }
    @media (min-width: 1000px) {
      font-size: 55px;
    }
  }

  .social-link {
    margin-left: 15px;
    padding: 0px 5px;
    text-decoration: none;
    color: #000000;
    margin-right: 10px;

    &:hover {
      color: #d91b3c;
    }
  }

  .text-primary {
    color: $primary;
  }

  .submit-email {
    display: flex;
    height: 60px;
    margin-bottom: 30px;
  }

  input {
    width: 90%;
    height: 100%;
    padding: 0 2rem;
    background: #ffffff;
    border: 1px solid #e53a5e;
    border-right: 0px;
    box-sizing: border-box;
    border-radius: 5px;
    font-size: 18px;
    margin-top: 1rem;
  }

  .arrow-button {
    width: 197px;
    height: 60px;
    margin-top: 1rem;
    margin-left: -5px;
    font-size: 20px;
    background: #e53a5e;
    border-radius: 5px;
    border-top: 1px solid $primary;
    border-right: 1px solid $primary;
    border-bottom: 1px solid $primary;
    border-left: 1px solid $primary;
  }

  .important-button {
    width: 197px;
    height: 60px;
    margin-top: 1rem;
    font-size: 20px;
    background: #e53a5e;
    border-radius: 5px;

    &:hover {
      background-color: $white;
      color: $primary;
      border: 1px solid $primary;
    }
  }
}

.header-drawer {
  .menu-mobile-hidden {
    @media (max-width: 800px) {
      display: none;
    }
  }
  .menu-desktop-hidden {
    @media (min-width: 800px) {
      display: none;
    }
  }
  .ant-drawer-body {
    background-color: #fff9f6;
    padding: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    .accordion-toggle {
      .icon {
        transition: 0.25s all cubic-bezier(0.075, 0.82, 0.165, 1);
      }
      .toggle-icon.toggled {
        .icon {
          transform: rotate(-90deg);
        }
      }
    }
    .container-fluid {
      margin: 0;
    }
    .close {
      position: absolute;
      top: 15px;
      right: 5rem;
      @media (max-width: 800px) {
        right: 2rem;
      }
      button {
        padding: 0;
        background-color: transparent;
        border: none;
        outline: none;
      }
    }
  }
  .nav-active .ant-typography {
    color: #e5395d;
  }
  .menu-section {
    padding: 4.5rem;
    @media (max-width: 800px) {
      padding: 5rem 2rem 0;
    }
    .ant-row {
      margin-bottom: 1.6rem;
    }
  }
  .ant-anchor-link {
    margin: 2rem 0;
  }
  a.ant-anchor-link-title {
    @extend .link;
  }
  a.ant-anchor-link-title-active {
    color: $red;
  }
  .anchor-header {
    width: 100%;
    color: #e43c5c;
    display: flex;
    align-items: center;
    .ant-anchor-ink {
      display: none;
    }
    .ant-typography {
      margin-bottom: 0;
      // color: #E43C5C;
    }
    .icon {
      margin-right: 1.25rem;
    }
  }
  .social-section {
    background-color: #f8efed;
    padding: 1.5rem 5rem;
    // margin-top: auto !important;
    @media (max-width: 800px) {
      padding: 3rem 2rem;
    }
    .ant-typography {
      margin-bottom: 0;
    }
    .icon {
      margin-right: 1rem;
      path {
        fill: #e43c5c !important;
      }
    }
  }
  .nav-links {
    padding: 1.5rem 1rem 0;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    max-height: 215px;
    margin-bottom: 1rem;
    @media (max-width: 800px) {
      flex-wrap: nowrap;
      max-height: none;
    }
    li {
      margin-bottom: 0.5rem;
      font-size: 1.5rem;
      position: relative;
      display: flex;
      align-items: center;
      &::before {
        content: "";
        // position: absolute;
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 2px;
        background-color: #e43c5c;
        margin-right: 0.75rem;
      }
      a {
        text-decoration: none;
        color: inherit;
        &:hover {
          color: #e43c5c;
        }
      }
    }
  }
}

.ant-anchor-ink-ball {
  border-color: $red;
}

// Charts
#chartArea {
  position: relative;
  width: 400px;
  margin: 0 auto;
  height: 400px;
  font-size: 16px;

  div {
    background: #fff;
    width: 275px;
    padding: 10px;
    box-sizing: border-box;
    border-radius: 10px;
    border: 2px solid #eb7087;
  }

  img {
    width: 100px;
    height: 100px;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 50%;
    transform: translate(-50%, -50%);
    // background: red;
  }
}

.input-email {
  position: relative;
  margin-top: 2rem;
  input[type="email"] {
    padding: 1rem;
    border-radius: 8px;
    color: #fff;
    background-color: #000;
    text-align: left;
  }
  button,
  button.ant-btn {
    position: absolute;
    background-color: transparent;
    background: transparent !important;
    outline: none;
    padding: 0 !important;
    border: none;
    border-radius: 8px !important;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    height: auto;
    width: auto !important;
  }
}
