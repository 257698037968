// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

//
// Color system
//

// CDN
$CDN: 'https://assets.cherry.network';

// Grays
$gray-100: #f7f7f7 !default;
$gray-200: #f8fafd !default;
$gray-300: #e7eaf3 !default;
$gray-400: #bdc5d1 !default;
$gray-500: #97a4af !default;
$gray-600: #8c98a4 !default;
$gray-700: #77838f !default;
$gray-900: #1e2022 !default;

// Colors
$white: #ffffff !default;
$black: #000000 !default;
$blue: #3954F7 !default;
$teal: #17AA81 !default;
$cyan: #00dffc !default;
$yellow: #F6BF33 !default;
$red: #D91B3C !default;
$pink: #E33B5C !default;
$indigo: #2d1582 !default;
$grey: #778692 !default;
$btn-primary-color: #E33B5C !default;

// Theme Colors
$primary: #D91B3C;
$primary-lighter: #F6F0EE;
$primary-light: #EF648B;
$secondary: #EEF5FC !default;
$success: #45D99A !default;
$info: #1B1443 !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $white !default;
$dark: $gray-900 !default;

// Font Sizes 
$text-header-1: 67px !important;
$text-header-1-line-height: 75px !important;
$text-header-2: 54px !important;
$text-header-2-line-height: 60px !important;
$text-header-3: 42px !important;
$text-header-3-line-height: 50px !important;
$text-header-4: 34px !important;
$text-header-4-line-height: 40px !important;
$text-header-5: 27px !important;
$text-header-5-line-height: 35px !important;

$text-body: 22px;
$text-body-line-height: 33px;
$text-button: 20px;
$text-button-line-height: 30px;
$text-input: 18px;
$text-input-line-height: 25px;
$text-small-font: 16px;
$text-small-font-line-height: 23px;
$text-body2: 16px;
$text-body2-line-height: 23px;

// Spacing
$section-y-padding: 6.5rem !default;