@mixin rotate-bubble($deg) {
	transform: rotate($deg * 1deg);
	.inner {
		transform: rotate(-$deg * 1deg);
	}
}

.token-sale-layout {

		background: #F6F0EE !important;
		padding: 0;
		height: auto;
		line-height: 1.6;
		display: flex;
		
	
		.token-sale {
			margin-top: 70px;
		
			.cross-chain-interoperability {
				padding: 10rem 2rem;
				background: url($CDN + '/landing/token-sale/wave_bg1.svg');
				background-position: bottom;
				background-repeat: no-repeat;
				background-size: cover;
				background-position: center;
				position: relative;
				min-height: 932px;
				display: flex;
				align-items: center;
				// .background {
				// 	position: relative;
				// 	width: 100%;
				// }
				.container {
					position: relative;
					// top: 50%;
    				// left: 50%;
    				// width: 100%;
    				// transform: translate(-50%, -60%);
				}
				.image-wrap {
					margin-bottom: 2rem;
					@media (min-width: 992px) {
						margin-bottom: 0;
					}
				}
				.text-wrap {
					@media (min-width: 992px) {
						padding: 5rem;
						padding-right: 0;
					}
				}


			}

			.cherry-treasury {
				padding: 5rem 2rem;
				margin-top: 4rem;
				padding: 15rem 2rem;
				background: url($CDN + '/landing/token-sale/wave_bg2.svg');	
				background-repeat: no-repeat;
				background-size: cover;
				background-position: center;

				position: relative;
				min-height: 932px;
				display: flex;
				align-items: center;

				.container {
					position: relative;

				}
				.image-wrap {
					margin-bottom: 2rem;
					@media (min-width: 992px) {
						margin-bottom: 0;
					}
				}
				.text-wrap {
					@media (min-width: 992px) {
						padding: 5rem;
						padding-right: 0;
					}
				}
			}

			.cherrynomics {
				padding: 3rem 2rem;
				.background {
					position: absolute;
					z-index: 1;
				}

				.bubbles {
					display: flex;
					align-items: end;
					justify-content: center;
					flex-wrap: wrap;
					position: relative;
          

					.background {
						top: 50%;
						transform: translateY(-50%);
						@media (min-width: 992px) {
							top: 0;
							transform: translateX(0);
						}
					}
					@media (min-width: 768px) {
						margin-top: 7rem;	
					}
					.bubble {
						transform-origin: 50% 60.4%;
						z-index: 3;

            .text-16 {
              font-size: 18px !important; 
              line-height: 1.6rem !important;
            }

						.stalk {
							display: block;
							margin: 0 auto 0.5rem;
							width: 20%;
							// @media (max-width: 500px) {
							// 	width: 25%;
							// }
						}
						.ant-typography {
							margin-bottom: 0;
							color: #FFF;
						}
						.inner {
							padding: 1rem;
							border-radius: 50%;
							display: flex;
							align-items: center;
							justify-content: center;
							width: 100%;
							box-shadow: 0px 10px 33px rgba($color: #C84949, $alpha: 0.39);
							background: radial-gradient(circle, rgba(255,123,149,1) 0%, rgba(217,43,77,1) 100%);
							h3 {
								font-size: 32px;
               					letter-spacing: -1px !important;
							}
							span {
								font-size: 22px;
								margin-top: 0.25em;
								display: block;
							}
							@media (max-width: 500px) {
								h3 {
									font-size: 4.5vw;
								}
								span {
									font-size: 3vw;
								}
							}
						}
						.inner::before {
							content: "";
							width: 1px;
							margin-left: -1px;
							float: left;
							height: 0;
							padding-top: 100%;
						}
						.inner::after { /* to clear float */
							content: "";
							display: table;
							clear: both;
						}
						&#bubble-1 {
							width: 19%;
							.stalk {
								transform: rotateY(180deg);
							}
							@media (max-width: 992px) {
								width: 30%;
								min-width: 30%;
							}
							@media (max-width: 500px) {
								width: 50%;
							}
						}
						&#bubble-2 {
							width: 14%;
							margin-top: 184px;
							@include rotate-bubble(-15);
							@media (max-width: 992px) {
								width: 24%;
							}
							@media (max-width: 500px) {
								width: 41%;
								margin-top: 93px;
							}
						}
						&#bubble-3 {
							width: 21%;
							margin-top: -114px;
							margin-left: -62px;
							@include rotate-bubble(30);
							@media (max-width: 992px) {
								width: 35%;
								min-width: 35%;
								@include rotate-bubble(-30);
							}
							@media (max-width: 500px) {
								width: 46%;
								margin-right: 35px;
							}
						}
						&#bubble-4 {
							width: 23%;
							margin-top: 129px;
							margin-left: -87px;
							@include rotate-bubble(10);
							@media (max-width: 992px) {
								width: 34%;
								margin-top: 0;
								margin-right: 40px;
							}
							@media (max-width: 500px) {
								width: 48%;
								margin-top: 0;
								margin-left: -44px;
								margin-right: 0;
							}
						}
						&#bubble-5 {
							width: 21%;
							margin-top: -33px;
							margin-left: 3px;
							@include rotate-bubble(-15);
							@media (max-width: 992px) {
								width: 35%;
								min-width: 35%;
							}
							@media (max-width: 500px) {
								width: 46%;
								margin-right: 115px;
								margin-top: -61px;
							}
						}
					}	
				}
			}

			.cherry-distribution {
				margin-top: 4rem;
				padding: 15rem 2rem;
				background: url($CDN + '/landing/token-sale/wave_bg2.svg');	
				background-repeat: no-repeat;
				background-size: cover;
				background-position: center;

				.container > .ant-row:nth-child(1) {
					margin-bottom: 5rem;
				}

				.text-40 {
					font-size: 40px;
				}

				.distribution {
					margin-bottom: 1rem;
					&:last-child {
						margin-bottom: 0;
					}

					.legend-bullet {
						border-radius: 4px;
						margin-right: 1.25rem;
						display: inline-block;
						width: 2.8rem;
						height: 1rem;
						@media (max-width: 991px) {
							margin-right: 1rem;
						}
					}

          			.bold {
          			  font-weight: bold;
          			  letter-spacing: -1px !important;
          			}
          
          

				}

				.image-wrap {
					img {
						display: block;
						margin: 0 auto;
					}
				}

			}

			.cherry-vesting-schedule {
				margin: 4rem 2rem;

        @media (min-width: 350px) {
          margin: 4rem 1rem;
				}

				.container {
					.image-wrap {
						img {
							width: 100%;
						}
					}
					a {color: $primary;}

					h1 {
						text-align: center;
					}

					>.ant-row:nth-child(1) {
						margin-bottom: 5rem;
						text-align: center;
						@media (max-width: 991px) {
							div.ant-typography {
								text-align: left;
							}
						 }
					}

					// .ant-typography:nth-child(2) {
					// 	max-width: 900px;
					// 	margin: 0 auto;
					// 	text-align: center;
					// }

					.legends {
						display: flex;
						margin-top: 3rem;
						.muted {
							font-size: 14px;
              				line-height: 1.5rem !important;
							@media (max-width: 991px) {
								display: none;
							}
						}
						.legend-bullets {
							min-width: 45%;
							display: flex;
							justify-content: center;
							@media (max-width: 991px) {
								justify-content: flex-start;
								width: 100%;
							}
						}
						.muted {
							margin-right: 3rem;
							@media (max-width: 991px) {
								margin-right: 0;
							}
						}
						ul {
							list-style: none;
							display: flex;
							flex-direction: column;
							flex-wrap: wrap;	
							padding: 0;
							margin-left: -15px;
    						margin-right: -15px;
							@media (max-width: 991px) {
								max-height: calc(((22px * 1.6) + 0.75rem) * 3);
								align-content: space-between;
								width: 100%;
							}
							li {
								display: flex;
								align-items: center;
								margin-bottom: 0.75rem;
								font-size: 1.25rem;
								padding-left: 15px;
								padding-right: 15px;
								&:last-child {
									margin-bottom: 0;
								}
								.legend-bullet {
									border-radius: 4px;
									margin-right: 1.25rem;
									display: block;
									width: 3.5rem;
									height: 1.25rem;
									@media (max-width: 400px) {
										width: 2.45rem;
									}
								}
								@media (max-width: 991px) {
									font-size: 1.25rem;
									.legend-bullet {
										margin-right: 1rem;
									}
								}
							}
						}
					}
					
				}
			}


		// Cherry Top Container
		.cherry-public-sale-section {
			padding: 5rem 0rem;
			@media (min-width: 992px) {
				padding: 0;
			}
			.bg-image {
				position: absolute;
				z-index: 1;
			}
			.image-wrap {
				@media (max-width: 991px) {
					margin-top: 2rem;
				}
				img {
					width: 100%;
				}
			}
			.main-container {
				min-height: calc(100vh - 70px);
        
				display: flex;
				align-items: center;
				text-align: center;
				margin: 0 auto;
				width: 100%;
				position: relative;
				z-index: 5;
				h1.ant-typography {
					margin-bottom: 2rem;
				}
				@media (max-width: 1280px) {
					padding: 0 2rem;
				}

        @media (min-width: 500px) {
          padding: 0rem 2rem;
				}

        @media (min-width: 350px) {
          padding: 0rem 0.75rem;
				}

        .token-sale-main-title {

          @media (max-width: 500px) {
            text-align: center;
          }

        }

				.countdown {
					display: flex;
					@media (max-width: 600px) {
						flex-wrap: wrap;
						justify-content: center;
            padding-left: 1.5rem;

					}
          @media (max-width: 375px) {
						flex-wrap: wrap;
						justify-content: center;
            
            padding-left: .75rem;
					}

          
					> div > div {
						margin-bottom: 2rem;
						background-color: #FFF;
						width: 100px;
						margin-right: 0.75rem;
						font-size: 1.25rem;
            min-height: 80px !important;
						text-align: center;
						// font-variant-numeric: tabular-nums;
						padding: 1rem;
						border-radius: 1rem;
						.ant-typography {
							margin-bottom: 0;
							color: #E43C5C;
						}
						h2.ant-typography {
							font-size: 30px;
						}
					}
				}
        
        .accepted-payments {
          margin-top: 3rem;
          display: flex;
          align-items: center;
          justify-content: center;

          .accepted-here-text {
            font-size: 1.25rem;
            line-height: 1.6;
            margin-top: 1.5rem;

            @media screen and (max-width: 600px) {
              display: none;
            }

            @media screen and (min-width: 601px) {
              display: block;
            }
          }

          .accepted-payment-item {
            margin-left: 2rem;
          }

        }

        .accepted-payments-mobile {
          .accepted-here-text {
            font-size: 1.25rem;
            line-height: 1.6;
            margin-top: .8rem;

            @media screen and (max-width: 600px) {
              display: block;
            }

            @media screen and (min-width: 601px) {
              display: none;
            }

          } 
        }
      
			}
	
			.top-left-container {
				display: flex;
				flex-direction: column;
				text-align: left;
				
				.primary {
					color: $primary;
				}
	
				.mb-10 {
					margin-bottom: 10px;
				}


				.text-20 {
					font-size: 20px;
				}
				.text-30 {
					font-size: 40px;
				}
				.text-40 {
					font-size: 40px;
				}

				
				.primary-button {
					background-color: $btn-primary-color;
					color: $white;
					// max-width: 400px;
					height: 60px;
          
          font-size: 1.25rem;
          font-weight: bold;
          padding: 5px 25px;
          border: none;
			border-radius: 12px;
          text-align: center;
          margin-top: 2rem !important;
          padding: 1rem 2rem;

          @media (max-width: 500px) {

            width: 100%;

            .arrow-button {
              display: none;
            }
          }

          @media (max-width: 375px) {

            width: 99%;

            .arrow-button {
              display: none;
            }
          }

          .arrow-button {
            margin-left: 2rem;
            width: 23px;
          }
					
		&:hover {
			border: 1px solid $primary;
			background-color: $primary-lighter;
			color: $primary;

    		.arrow path {
        	  stroke: $primary;
        	}
		}
		
				}
	
			}
      
      
    }



		.the-cherry-token {
			padding: 10rem 2rem;

			.cherry-token-container { 
				width: 100%;
				max-width: 1280px;
				margin: 0 auto;

			.cherry-token-top-container {
				text-align: left;
				.image-wrap {
					margin-top: 3rem;
				}
				.bold {
					font-weight: bold;
				}
				@media (max-width: 991px) {
					margin-bottom: 4rem;
				}
			}

			.cherry-token-top-right-container  {
				@media (min-width: 992px) {
					margin-top: 25px;	
				}

				.utility {
					margin-bottom: 3rem;
					.icon {
						margin-bottom: 1rem;
					}
				}
			}

		}

		}

		
		}


		.mb-5 {
			margin-bottom : 5px !important;
		}

		.mb-10 {
			margin-bottom: 10px !important;
		}

		.mb-15 {
			margin-bottom: 15px !important;
		}

		.mt-5 {
			margin-top: 5px !important;
		}
	
		.mt-10 {
			margin-top: 10px !important;
		}

		.mt-15 {
			margin-top: 15px !important;
		}

}

.join-waiting-list-modal {
  padding: 0px;
  margin: 0px;
  
  
  .join-the-waiting-list {
    border-radius: 24;
  }

  .ant-spin-dot-item {
	  background-color: #FFF;
	  border: 1px solid #D91B3C;
  }

  &.error .required {
	  border: 1px solid #D91B3C;
  }

  .PhoneInputInput {
	  background: transparent;
	  border: none;
	  outline: none;
	  transition: all 0.3s;
	  &:focus {
		outline: none;
	  }
  }

  .PhoneInputInput::placeholder {
	  color: #000;
  }

  	.PhoneInputCountry {
		display: none;
	}

  .react-tel-input {
	font-family: inherit;
	padding: 0 2rem;
	border-radius: 8px;
	background-color: #f3f3f3;
	.flag-dropdown {
		border: none;
		margin-right: 12px;
	}
	.selected-flag {
		padding: 0;
	}
	.selected-flag:hover {
		background: transparent;
	}
	input.form-control {
		margin-left: 16px;
	}
  }

  .react-tel-input .form-control {
	  width: 100%;
	  @extend .input;
	  padding-left: 25px !important;
      padding-right: 0 !important;
  }

  .PhoneInput--focus {
	transition: all 0.3s;
    border-right-width: 1px !important;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

  .ant-select-arrow {
	  color: rgba(0,0,0,1);
	  right: 2rem;
  }

  .text-area {
    text-align: left;
    background-color: #f3f3f3;
    color: #000;
    padding: 0 2rem;
    min-height: 80px !important;
    border-radius: 8px;
    font-size: 18px;
    border: 0;
    outline: 0;
    margin: .5rem 0rem;

    &::placeholder {
      color: #000;
    }

    &:focus {
      border: 0;
      outline: 0;
    }

    &:hover {
      border: 0;
      outline: 0;
    }
  }

  .input {
    text-align: left;
    background-color: #f3f3f3;
    color: #000;
    padding: 0 2rem;
    height: 45px;
    border-radius: 8px;
    font-size: 18px;
    border: 0;
    outline: 0;
    margin: .5rem 0rem;

    &::placeholder {
      color: #000;
    }

    &:focus {
      border: 0;
      outline: 0;
    }

    &:hover {
      border: 0;
      outline: 0;
    }
  }

  .select_amount {
    width: 100%;
    background-color: #f3f3f3;
    color: #000;
    height: 45px;
    border-radius: 8px;
    font-size: 18px;
    margin: .5rem 0rem;

	.ant-select-selection-placeholder {
		display: flex;
		align-items: center;
		color: inherit;
		font-weight: 400;
	}

    .ant-select-selector {
      background-color: #f3f3f3;
      height: 100%;
      width: 100%;
      padding: 0 2rem;
	  border-radius: 8px;
      border: none;
     

      &:focus {
        border: 0;
        outline: 0;
      }
  
      &:hover {
        border: 0;
        outline: 0;
      }

      ::after {
        background-color: #ff0;
        border: 0;
        outline: 0;
      }

      .ant-select-selection-search {
        border: 0;
        outline: 0;
        height: 100%;
      width: 100%;
      }

      span {
        height: 100%;
      width: 100%;
        border: 0;
        outline: 0;
      }

    }

    .ant-select-selection-item {
      background-color: #f3f3f3;
      padding: 0;
      padding-top: 7px;
      height: 100%;
      width: 100%;

      &:focus {
        border: 0;
        outline: 0;
      }
  
      &:hover {
        border: 0;
        outline: 0;
        
      }

      ::after {
        background-color: #ff0;
        border: 0;
        outline: 0;
      }

      .ant-select-selection-search {
        border: 0;
        outline: 0;
        height: 100%;
        width: 100%;
      }

      
    }




  }

  .primary-button {
    background-color: $primary;
    color: $white;
    width: 100%;
    height: 60px;
    font-size: larger;
    font-weight: bold;
    border-radius: 12px;
    text-align: center;
    margin-top: 2rem !important;
    padding: 1rem 2rem;

    &:hover {
      border: 1px solid $primary;
      background-color: $primary-lighter;
      color: $primary;
    }
  }

  .ant-checkbox .ant-checkbox-inner {
    background-color: $white;
    border-color: $primary;
  }
  
  .ant-checkbox-disabled .ant-checkbox-inner {

    background-color: gray;
    border-color: gray;
  }
  
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $primary;
    border-color: $primary;
  }

  .ant-modal-body {
    padding: 0px;
    margin: 0px;
    // border-radius: 24px;
    // background-color: transparent !important; 
    // overflow: hidden;
  }

  .ant-modal-content {
    background-color: transparent !important;
  }  

}
  
  
.ant-select-item { 
	padding: 7px 12px;
	font-size: 16px;
}