.page-503 {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba($color:#F6F0EE, $alpha: 0.5) 75.2%,
  ) !important;
  footer {
    background: transparent !important;
  }
  .hero {
    padding: 5rem 2rem;
    @media (max-width: 991px) {
      padding: 3rem 2rem 0;
    }
    margin-top: 65px;
    min-height: calc(100vh - 65px - 85px);
    display: flex;
    align-items: center;
    .title {
      margin-top: 0.5em;
    }
    .helpful-links {
      display: flex;
      flex-direction: column;
      a {
        margin-bottom: 0.75rem;
      }
    }
    .image-wrap {
      text-align: end;
    }
  }
}