@import "./_variables";
@import "./typography";
@import "./components";
@import "./token-sale";
@import "./homepage";
@import "./newsroom";
@import "./airdrop";
@import "./brand-assets";
@import "./error-page";


#root {
  overflow: auto;
}

#root {
  max-width: 100%;
  overflow-x: hidden;
	background-color: $primary-lighter;
}

html {
  font-size: 14px;
}

html,
body {
  font-family: "Visby Round CF";
  font-variant-numeric: normal;
  font-feature-settings: normal;
  font-weight: 500;
  min-height: 100%;
  height: auto;
  max-width: 100vw;
}

body {
  margin: 0;
  font-size: 1.57rem;
  font-display: fallback;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.primary {
  color: #d91b3c !important;
}

.primary-light {
  color: #ef648b !important;
}

.text-16 {
  font-size: 16px;
}

.text-18 {
  font-size: 18px;
}

.text-20 {
  font-size: 20px;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.bg-white {
  background-color: $white;
}

.ant-collapse {
  background-color: $white;
}

img {
  max-width: 100%;
  object-fit: contain;
}

svg {
  vertical-align: middle;
}

.bold {
  font-weight: bold;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

a {
  color: #e5395d;
  text-decoration: underline;
}

// body {
// 	padding-top: 65px;
// }

.shadow-none {
  box-shadow: none;
}

.btn-primary {
  background-color: #e5395d;
  padding: 1rem;
  text-decoration: none;
  border-radius: 2px;
}

.slick-custom-btn {
  background-color: #fff;
  color: #000;
  width: 100%;
  max-width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 4rem;
  font-weight: bolder;
  border-radius: 50%;
  align-self: center;
  outline: none;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 7;
  span {
    line-height: 4rem;
    transform: translateY(-0.4rem);
  }
  @media (max-width: 450px) {
    max-width: 3rem;
    height: 3rem;
    font-size: 3rem;
    span {
      line-height: 4rem;
      transform: translateY(-0.4rem);
    }
  }
  &-next {
    right: 0;
  }
  &-prev {
    left: 0;
  }
}

.bg-white {
  background-color: #fff;
}

.text-red {
  color: $red !important;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.react-rotating-text-cursor {
  animation: blinking-cursor 0.8s cubic-bezier(0.68, 0.01, 0.01, 0.99) 0s
    infinite;
}

@keyframes blinking-cursor {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.ant-drawer-content-wrapper {
  width: 100vw !important;
  max-width: 800px !important;
}

.mobile-hidden {
  @media (max-width: 800px) {
    display: none;
  }
}
.desktop-hidden {
  @media (min-width: 800px) {
    display: none;
  }
}

.text-container-large {
  display: block;
  max-width: 900px;
  margin: 0 auto;
}