.brand-assets-layout {
    background: #f6f0ee !important;

    .download-btn {
        background: none;
        padding: 0;
        outline: none;
        border: none;
        box-shadow: none;
        display: flex;
        align-items: center;
        color: #262626;
        &:hover {
            color: #E33B5C;
        }
        &:active, &:focus {
            outline: none;
            border: none;
        }
        .icon {
            margin-right: 0.75rem;
        }
        &::after {
            display: none;
        }
    }

    .brand-assets-content {
        margin-top: 70px;
    
        .brand-assets-hero {
            min-height: calc(100vh - 70px);
            display: flex;
            align-items: center;
            .bg-image {
              position: absolute;
              z-index: 1;
            }
            .container {
              padding: $section-y-padding 0rem;
              z-index: 5;
              @media (max-width: 1280px) {
                padding: 0 2rem;
              }
              .image-wrap {
                  text-align: center;
              }
            }
        }

        .cherry-logos {
            padding: $section-y-padding 2rem;
            h2.ant-typography {
                margin-bottom: 5rem;
            }
            .image-wrap {
                background-color: #FFF;
                display: flex;
                align-items: center;
                justify-content: center;
                min-height: 373px;
                border-radius: 2rem;
                padding: 3rem;
                margin-bottom: 0.8rem;
            }
            .download-links {
                display: flex;
                .download-btn {
                    margin-right: 2rem;
                }
            }
        }

        .branding-guides {
            padding: $section-y-padding 2rem;
            h2.ant-typography {
                margin-bottom: 5rem;
            }
            .image-wrap {
                background-color: #FFF;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                min-height: 373px;
                border-radius: 2rem;
                margin-bottom: 0.8rem;
                padding: 3rem;
                .icon {
                    margin-bottom: 1rem;
                }
            }
        }
    
        .join-the-cherry-ecosystem {
            padding: $section-y-padding 0;
            
            @media (max-width: 786px) {
            }
      
            .main-container {
              width: 100%;
              max-width: 1200px;
              margin: 0 auto;
              background: #e33b5c;
              box-sizing: border-box;
              padding: 60px;
              border-radius: 32px;
              position: relative;
              @media (max-width: 991px) {
                padding: 0 2rem 4rem;
              }
      
              .image-ecosystem {
                position: absolute;
                bottom: 90px;
                right: 2rem;
                height: 91%;
                z-index: 1;
                @media (max-width: 991px) {
                  right: 0;
                  bottom: 0;
                  height: auto;
                  position: relative;
                  margin-top: -18%;
                  margin-bottom: 2rem;
                }
              }
      
              .ant-row {
                z-index: 5;
                position: relative;
              }
      
              @media (max-width: 800px) {
                text-align: center;
              }
      
              @media (max-width: 550px) {
                border-radius: 0;
              }
      
              @media (max-width: 786px) {
                .ant-col:nth-child(1) {
                  order: 2;
                }
      
                .ant-col:nth-child(2) {
                  order: 1;
                  margin-bottom: 2rem;
                }
              }
      
              h1,
              h2,
              h3 {
                color: #fff;
              }
      
              .separator {
                margin-top: 2rem;
                display: flex;
                justify-content: space-between;
                align-items: top;
                gap: 25px;
      
                @media (max-width: 800px) {
                  flex-direction: column;
                  text-align: center;
                  .column {
                    text-align: center;
                  }
                }
      
                @media (max-width: 550px) {
                  .column {
                    text-align: left;
                  }
                }
      
                .column {
                  display: flex;
                  flex-direction: column;
                  @media (max-width: 991px) {
                    margin-bottom: 2rem;
                    &:last-child {
                      margin-bottom: 0;
                    }
                  }
      
                  @media (max-width: 550px) {
                    input,
                    .ant-btn {
                      width: 100%;
                    }
                    input {
                      text-align: left;
                    }
                  }
      
                  .ant-typography:nth-child(2) {
                    margin-top: -1rem;
                    color: #f3b3c0;
                  }
      
                  .text-white {
                    color: $white;
                  }
      
                  .ant-btn {
                    background: #fff;
                    color: #e33b5c;
                    padding: 0 2rem;
                    height: 50px;
                    border: none !important;
                    font-weight: default !important;
                    border-radius: 12px;
                    margin-top: auto;
                    @media (min-width: 992px) {
                      max-width: 300px;
                    }
                  }
      
                  .ant-btn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-decoration: none;
                    .icon {
                        margin-right: 0.75rem;
                    }
                  }

                  .image-wrap {
                      img {
                          width: 100%;
                      }
                  }

                }
              }
            }
          }
    }
}
