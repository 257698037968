// Visby Round CF
@font-face {
    font-family: 'Visby Round CF';
    src: url('../fonts/VisbyRoundCF-Regular.woff2') format('woff2'),
         url('../fonts/VisbyRoundCF-Regular.woff2') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Visby Round CF';
    src: url('../fonts/VisbyRoundCF-Medium.woff2') format('woff2'),
        url('../fonts/VisbyRoundCF-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Visby Round CF';
    src: url('../fonts/VisbyRoundCF-Light.woff2') format('woff2'),
        url('../fonts/VisbyRoundCF-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Visby Round CF';
    src: url('../fonts/VisbyRoundCF-Light.woff2') format('woff2'),
        url('../fonts/VisbyRoundCF-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Visby Round CF';
    src: url('../fonts/VisbyRoundCF-Regular.woff2') format('woff2'),
        url('../fonts/VisbyRoundCF-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Visby Round CF';
    src: url('../fonts/VisbyRoundCF-Medium.woff2') format('woff2'),
        url('../fonts/VisbyRoundCF-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Visby Round CF Extra';
    src: url('../fonts/VisbyRoundCF-ExtraLight.woff2') format('woff2'),
        url('../fonts/VisbyRoundCF-ExtraBold.woff2') format('woff2'),
        url('../fonts/VisbyRoundCF-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Visby Round CF Demi';
    src: url('../fonts/VisbyRoundCF-DemiBold.woff2') format('woff2'),
        url('../fonts/VisbyRoundCF-DemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Visby Round CF';
    src: url('../fonts/VisbyRoundCF-Heavy.woff2') format('woff2'),
        url('../fonts/VisbyRoundCF-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Visby Round CF Extra';
    src: url('../fonts/VisbyRoundCF-ExtraBold.woff2') format('woff2'),
        url('../fonts/VisbyRoundCF-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Visby Round CF Demi';
    src: url('../fonts/VisbyRoundCF-DemiBold.woff2') format('woff2'),
        url('../fonts/VisbyRoundCF-DemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Visby Round CF Extra';
    src: url('../fonts/VisbyRoundCF-ExtraLight.woff2') format('woff2'),
        url('../fonts/VisbyRoundCF-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Visby Round CF';
    src: url('../fonts/VisbyRoundCF-Bold.woff2') format('woff2'),
        url('../fonts/VisbyRoundCF-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Visby Round CF';
    src: url('../fonts/VisbyRoundCF-Heavy.woff2') format('woff2'),
        url('../fonts/VisbyRoundCF-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Visby Round CF';
    src: url('../fonts/VisbyRoundCF-Bold.woff2') format('woff2'),
        url('../fonts/VisbyRoundCF-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

// Font Sizes
h1, h1.ant-typography {
    font-size: 2.75rem;
    @media (min-width: 768px) {
        font-size: 4.64rem;   
    }
}

h2, h2.ant-typography {
    font-size: 2.75rem;
    @media (min-width: 768px) {
        font-size: 4rem;   
    }
}

h3, h3.ant-typography {
    font-size: 1.7rem;
    @media (min-width: 768px) {
        font-size: 2rem;   
    }
}

h4, h4.ant-typography {
    @media (min-width: 768px) {
        font-size: 2.43rem;
    }
}

h5, h5.ant-typography {
    @media (min-width: 768px) {
        font-size: 1.92rem;
    }
}

.small {
    font-size: 1rem;
}

.ant-btn {
    font-size: 20px;
}