// fonts
$fw-normal: 400;

// colors
$teal: #7ec4c1;
$gray-e3: #e3e3e3;

// sizes
$right-padding: 2.86em;
$margin: 40px;
$trans-short: 200ms;
$icon-easing: ease-in-out;

// breakpoints
$break-lg: 1180px;