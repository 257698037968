.newsroom-layout {
  background: #f6f0ee !important;
  padding: 0;
  height: auto;
  line-height: 1.6;
  display: flex;

  .newsroom-content {
    margin-top: 70px;

    // top section
    .news-top-main-section {
      padding: 5rem 0rem;
      @media (min-width: 992px) {
        padding: 0;
      }
      .external-link {
        font-size: 18px !important;
        line-height: 1.6;
        text-decoration: none !important;

        &:hover {
          font-size: 20px;
          color: #d65167f5;
        }
        
      }

      .main-container {

        display: flex;
        align-items: center;
        text-align: center;
        margin: 0 auto;
        width: 100%;
        position: relative;
        z-index: 5;
        h1.ant-typography {
          margin-bottom: 2rem;
        }
        @media (max-width: 1280px) {
          padding: 0 2rem;
        }

        @media (min-width: 500px) {
          padding: 0rem 2rem;
        }

        @media (min-width: 350px) {
          padding: 0rem 0.75rem;
        }

        .newsroom-main-title {
          
          @media (max-width: 760px) {
            text-align: center;
          }
        }

      }

      .top-left-container {
        display: flex;
        flex-direction: column;
        text-align: left;

        .primary {
          color: $primary;
        }


        .external-link {
          font-size: 18px !important;
          line-height: 1.6;
          text-decoration: none !important;

          &:hover {
            font-size: 20px;
            color: #aa0722f5;
          }
          
        }

        
      }
    }

    // news feed section
    .newsfeed-section {
        padding: 10rem 2rem;
  
        .newsfeeds { 
          width: 100%;
          max-width: 1280px;
          margin: 0 auto;          
        }  

        .newscard {

          border-radius: 24px !important;

          .image-container {
            position: relative;

            .date-card {
              position: absolute;
              top: 8px;
              right: 16px;
              
              height: 100px;
              width: 75px;
              background-color: #F6F0EE;
              border-radius: 12px;

              .center-text {
                margin: 0;
                position: absolute;
                top: 50%;
                left: 50%;
                font-size: 22px !important;
                color: $black;
                line-height: 1.6;
                text-align: center;
                font-weight: bold;
                word-wrap: normal;
                -ms-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
              }

            }


          }
          
          
          .news-detail {
            padding: 1rem 2rem;
            display: flex;
            flex-direction: column;
            height: 100%;

            .text-22 {
              font-size: 22px !important;
              line-height: 1.6;
            }

            .external-link {
              font-size: 18px !important;
              line-height: 1.6;
              text-decoration: none !important;
              margin-top: auto;

              &:hover {
                font-size: 20px;
                color: #aa0722f5;
              }



              
            }

          }
        }
    }
  }
}


.news-detail {
  background: #f6f0ee !important;
  padding: 0;
  height: auto;
  line-height: 1.6;
  display: flex;

  .news-detail-content {
    margin-top: 70px;

    // top section
    .news-section {
      margin-top: 70px;
      padding: 5rem 0rem;
      @media (min-width: 992px) {
        padding: 0;
      }
      .external-link {
        font-size: 18px !important;
        line-height: 1.6;
        text-decoration: none !important;

        &:hover {
          font-size: 20px;
          color: #d65167f5;
        }
        
      }

      .main-container {

        display: flex;
        align-items: center;
        text-align: center;
        margin: 0 auto;
        width: 100%;
        position: relative;
        z-index: 5;
        h1.ant-typography {
          margin-bottom: 2rem;
        }
        @media (max-width: 1280px) {
          padding: 0 2rem;
        }

        @media (min-width: 500px) {
          padding: 0rem 2rem;
        }

        @media (min-width: 350px) {
          padding: 0rem 0.75rem;
        }

        .newsroom-main-title {
          color: $primary;
          text-align: center;
          
          @media (max-width: 760px) {
            text-align: center;
          }
        }

      }

      .top-left-container {
        display: flex;
        flex-direction: column;
        text-align: left;

        .primary {
          color: $primary;
        }


        .external-link {
          font-size: 18px !important;
          line-height: 1.6;
          text-decoration: none !important;

          &:hover {
            font-size: 20px;
            color: #aa0722f5;
          }
          
        }

        
      }
    }
    // End of top section

    // news feed section
    .newsfeed-section {
        padding: 10rem 2rem;
  
        .newsfeeds { 
          width: 100%;
          max-width: 1280px;
          margin: 0 auto;          
        }  

        .newscard {

          border-radius: 24px !important;

          .image-container {
            position: relative;

            .date-card {
              position: absolute;
              top: 8px;
              right: 16px;
              
              height: 100px;
              width: 75px;
              background-color: #F6F0EE;
              border-radius: 12px;

              .center-text {
                margin: 0;
                position: absolute;
                top: 50%;
                left: 50%;
                font-size: 22px !important;
                color: $black;
                line-height: 1.6;
                text-align: center;
                font-weight: bold;
                word-wrap: normal;
                -ms-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
              }

            }


          }
          
          
          .news-detail {
            padding: 1rem 2rem;
            display: flex;
            flex-direction: column;
            height: 100%;

            .text-22 {
              font-size: 22px !important;
              line-height: 1.6;
            }

            .external-link {
              font-size: 18px !important;
              line-height: 1.6;
              text-decoration: none !important;
              margin-top: auto;

              &:hover {
                font-size: 20px;
                color: #aa0722f5;
              }



              
            }

          }
        }
    }
  }

  .newscard {
    
    border-radius: 33px !important;

    .image-container {
      position: relative;

      .date-card {
        position: absolute;
        top: 8px;
        right: 16px;
        
        height: 100px;
        width: 100px;
        background-color: #F6F0EE;
        border-radius: 12px;

        .center-text {
          margin: 0;
          position: absolute;
          top: 50%;
          left: 50%;
          font-size: 22px !important;
          color: $black;
          line-height: 1.6;
          text-align: center;
          font-weight: bold;
          word-wrap: normal;
          -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
        }

      }


    }
    
    
    .news-detail {
      padding: 1rem 2rem;
      display: flex;
      flex-direction: column;
      height: 100%;
      background-color: #fff !important;
      text-align: left;
      

      .text-22 {
        font-size: 22px !important;
        line-height: 1.6;
      }

      .external-link {
        font-size: 18px !important;
        line-height: 1.6;
        text-decoration: none !important;
        margin-top: auto;

        &:hover {
          font-size: 20px;
          color: #aa0722f5;
        }



        
      }

    }
  }
}

