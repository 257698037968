@import '../variables';

.accordionToggle {
    font-weight: $fw-normal;
    margin: 0;
    padding: 0.93em $right-padding 0.93em 0;
    cursor: pointer;
    position: relative;
    &.active {
        .accordionArrow {
            transform: translate(-0.2em, -50%) rotate(90deg);
        }
    }
}

.accordionArrow {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    transition: transform $trans-short $icon-easing;
}
