.d3-chart {
  position: relative;

  div.tooltip:before {
    position: absolute;
    border-left: solid transparent 10px;
    border-right: solid transparent 10px;
    border-top: solid red 9px;
    bottom: -10px;
    content: " ";
    height: 0;
    left: 50%;
    margin-left: -13px;
    width: 0;
  }

  div.tooltip {
    font-size: 14px;
    background: white;
    padding: 10px;
    text-align: center;
    position: absolute;
    border: 1px solid red;
    border-radius: 10px;
    opacity: 0;
    pointer-events: none;
    max-width: 112px;
    padding: 10px;
  }

  div.tooltip:after {
    border-left: solid transparent 10px;
    border-right: solid transparent 10px;
    border-top: solid white 10px;
    bottom: -10px;
    content: " ";
    height: 0;
    left: 50%;
    margin-left: -13px;
    position: absolute;
    width: 0;
  }

  svg {
    shape-rendering: auto;
  }

  .axis path,
  .axis line {
    fill: none;
    stroke: #262626;
  }

  text {
    font-size: 13px;
    color: #262626;
    font-family: "Visby Round CF";
    font-weight: 500;
    strokeWidth: 0;
  }

  path.domain {
    stroke: none;
  }

  .tick line {
    stroke: #ddd;
  }

  .chart {
    text-align: center;
    padding-top: 48px;
  }

  .legend text {
    font-size: 12px;
  }

  .d3-x-axis {
    background-color: red;
    .tick:nth-of-type(1) {
      text {
        text-anchor: start;
      }
    }
    .tick:last-of-type {
      text {
        text-anchor: end;
      }   
    }
  }

  .d3-y-axis {
    .tick:nth-of-type(1) {
      text {
        dominant-baseline: text-after-edge;
      }
    }
    .tick:last-of-type {
      text {
        dominant-baseline: central;
      }
    }
  }
}

.d3-pie-chart {
  text {
    font-size: 1.2rem;
    font-weight: 500;
  }
}